<template>
    <div class="personal-msg page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item label="关键字：" prop="queryText">
                <el-input v-model="searchData.queryText" clearable placeholder="ID、IP、漫画名称、作者"></el-input>
            </el-form-item>
            <el-form-item label="选择日期:" prop="queryText">
                <el-date-picker v-model="searchData.dateRange" style="width: 260px;" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
             <el-form-item label="操作行为:" prop="logType">
                <el-select v-model="searchData.logType" clearable placeholder="操作类型" style="width: 110px;">
                    <el-option v-for="(item, index) in logTypeList" :key="item" :label="item" :value="index + 1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" prop="action" label-width="2">
                <el-select v-model="searchData.action" clearable placeholder="行为类型" style="width: 110px;">
                    <el-option v-for="(item, index) in logActionList" :key="item" :label="item" :value="index + 1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <el-table class="personal-msg-table" ref="table" :data="tableList" border style="width: 100%">
            <el-table-column type="index" label="#" align="center" width="50"></el-table-column>
            <el-table-column prop="createTime" label="操作时间" align="center" width="150"></el-table-column>
            <el-table-column prop="action" label="操作行为" align="center" width="80">
                <template slot-scope="scope">{{scope.row.action | logActionFilter}}</template>
            </el-table-column>
            <el-table-column prop="description" label="操作对象" align="center">
                <template slot-scope="scope">
                    <actionObject :model="scope.row" />
                </template>
            </el-table-column>
            <el-table-column prop="userIp" label="IP地址" align="center" width="100"></el-table-column>
            <el-table-column prop="userName" label="操作账号" align="center" width="150"></el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:13:35
 * @LastEditTime: 2018-10-01 13:50:35
 * @Description: 个人中心-操作历史
 * @Company: 成都二次元动漫
 */
import { searchMixin, paginationMixin } from '@/mixins';
import { logActionList, logTypeList } from '@/libs/filters';
import actionObject from './actionObject';

export default {
    mixins: [searchMixin, paginationMixin],
    data() {
        return {
            activeIndex: '1',
            logActionList,
            logTypeList,
            searchData: {
                dateRange: null,
                queryText: null,
                startTime: null,
                endTime: null,
                order: 2, // 排序，1 正序、2 倒序
                queryAllFlag: false, // 如果是管理员、超级管理员（level 2、3），可查询所有的操作记录，默认查询当前自己的
                action: null, // 操作行为，1 新增、2 编辑、3 删除
                logType: null // 类型，1 普通、2 漫画
            },
            tableList: []
        };
    },
    watch: {
        'searchData.dateRange'(dateList = []) {
            const [startTime = null, endTime = null] = dateList | [];
            this.searchData.startTime = startTime;
            this.searchData.endTime = endTime;
        }
    },
    mounted() {
    },
    created() {
        this.searchHandle();
    },
    methods: {
        // 搜索, 广播查询到子组件
        searchHandle() {
            this.pagination.pageIndex = 1;
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.searchData = this.$options.data().searchData;
            this.searchHandle();
        },
        // 查询列表
        getTableList() {
            return this.$api('getOperatelogList', { ...this.searchData, ...this.pagination }).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows = [], pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    },
    components: {
        actionObject
    }
};
</script>
<style lang="scss">
    .personal-msg{
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
    }
</style>
