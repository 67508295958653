<template>
    <div class="log-obj">
        <!-- 普通操作类型 -->
        <div v-if="model.type === 1" class="log-obj-normal">
            {{model.description}}
        </div>
        <!-- 漫画操作类型 -->
        <div v-if="model.type === 2" class="log-obj-comic">
            <router-link class="color-green" :to="comicRoute">{{body.comicName}}</router-link>
            <router-link v-if="body.chapterId" :to="chapterRoute" class="color-green">{{body.chapterSN}} {{body.chapterName || ''}}</router-link>
            <template>{{model.description}}</template>
        </div>
        <!-- 功能操作类型 -->
        <div v-if="model.type === 3" class="log-obj-comic">
            {{model.description}}
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-10-03 15:55:43
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-23 15:52:51
 * @Description: 操作历史-操作对象格式化
 * @Company: 成都二次元动漫
 */
export default {
    props: {
        model: Object
    },
    data() {
        return {};
    },
    computed: {
        body() {
            return this.model.body ? JSON.parse(this.model.body) : {};
        },
        comicRoute() {
            const { comicId, comicName, authorName } = this.body;
            return { name: 'editComic', query: { comicId, comicName, authorName, editType: 'view', tabtype: 1 } };
        },
        chapterRoute() {
            const { comicId, comicName, authorName, chapterId } = this.body;
            return { name: 'editComicChapter', query: { type: 'view', comicName, comicId, chapterId, authorName, single: 1 } };
        }
    }
};
</script>

<style lang="scss">
</style>
